<template>
  <div id="checkout">
    <div id="main">
      <div id="content" class="container-fluid">
        <Reservation
            :bookingData="bookingData"
            :guest="{}"
            :confirmcode="''"
            :itinerary_id="''"
        />
      </div>

      <SidebarScalapay v-if="useSidebarScalapay" />
      <SidebarSatispay v-else-if="useSidebarSatispay" />
      <SidebarEtc v-else-if="this.$config.integration.integration == 'edenred_etc'" v-on:sidebar-order-confirmed="sidebarOrderConfirmed"/>
      <SidebarDc v-else-if="!mixinIsWelfare() && this.$config.integration.integration != 'jointly'" v-on:sidebar-order-confirmed="sidebarOrderConfirmed"/>
      <Sidebar v-else v-on:sidebar-order-confirmed="sidebarOrderConfirmed" />

    </div>
  </div>
</template>

<script>
  import Reservation from '../blocks/reservation'
  import Vue from "vue";

  export default {
    name: 'checkout',
    metaInfo: {
      title: 'checkout',
    },
    components: {
      Sidebar: () => import('../blocks/sidebar/sidebar').then(function(Sidebar) {
        if(process.env.VUE_APP_MODE != 'production') window.console.log('async Sidebar Component loaded')
        return Sidebar
      }),
      SidebarEtc: () => import('../blocks/sidebar/sidebar-etc').then(function(SidebarEtc) {
        if(process.env.VUE_APP_MODE != 'production') window.console.log('async SidebarEtc Component loaded')
        return SidebarEtc
      }),
      SidebarDc: () => import('../blocks/sidebar/sidebar-dc').then(function(SidebarDc) {
        if(process.env.VUE_APP_MODE != 'production') window.console.log('async SidebarDc Component loaded')
        return SidebarDc
      }),
      SidebarScalapay: () => import('../blocks/sidebar/sidebar-scalapay').then(function(SidebarScalapay) {
        if(process.env.VUE_APP_MODE != 'production') window.console.log('async SidebarScalapay Component loaded')
        return SidebarScalapay
      }),
      SidebarSatispay: () => import('../blocks/sidebar/sidebar-satispay').then(function(SidebarSatispay) {
        if(process.env.VUE_APP_MODE != 'production') window.console.log('async SidebarSatispay Component loaded')
        return SidebarSatispay
      }),
      Reservation
    },
    props:{
      useSidebarScalapay: {
        type: Boolean,
        default: false,
      },
      useSidebarSatispay: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        site: this.appConfig().site,
      }
    },
    computed: {
      bookingData: {
        get() { return this.$store.state.bookingData },
        set(value) { this.mixinSendMutation('setBookingData',value) }
      },
      geo: {
        get() { return this.$store.state.geo },
        set() {}
      },
    },
    mounted() {

      if(this.$gtm){

        this.$gtm.trackEvent({
          event: 'customEvent',
          category: 'userBehaviour',
          action: 'initiateCheckout',
          label: Vue.prototype.$config.integration.email,
          value: this.bookingData.live_price,
        });

        this.$gtm.trackEvent({
          event: 'customEvent', // Event type [default = 'interaction'] (Optional)
          category: 'partnerBehaviour',
          action: 'initiateCheckout',
          label: Vue.prototype.$config.integration.company,
          value: this.bookingData.live_price,
        });

        let eventGeoExtraValue = (this.geo.matchLevel == 'inSalabam')
            ? this.geo.inSalabamId
            : (this.geo.matchLevel == 'property')
                ? this.geo.propertyId
                : 0;

        this.$gtm.trackEvent({
          event: 'customEvent',
          category: 'initiateCheckoutSearch',
          action: this.geo.matchLevel,
          label: this.geo.label,
          value: eventGeoExtraValue,
        });

        this.$gtm.trackEvent({
          event: 'add_to_cart',
          ecommerce: {
            currency: "EUR",
            items: [
              {
                item_id: this.bookingData.hotelId + '-' + this.bookingData.roomId + '-' + this.bookingData.rateId,
                item_name: this.bookingData.hotelName,
                item_brand: this.site.brand,
                price: this.bookingData.live_price,
                item_category: "",
                quantity: 1,
                //id: "",
                //google_business_vertical: "retail"
              }
            ],
            transaction_id: "",
            affiliation: "",
            value: this.bookingData.live_price, // Sum of (price * quantity) for all items.
            tax: 0,
            shipping: 0,
            coupon: "",
          }
        });

        this.$gtm.trackEvent({
          event: 'begin_checkout',
          ecommerce: {
            currency: "EUR",
            items: [
              {
                item_id: this.bookingData.hotelId + '-' + this.bookingData.roomId + '-' + this.bookingData.rateId,
                item_name: this.bookingData.hotelName,
                item_brand: this.site.brand,
                price: this.bookingData.live_price,
                item_category: "",
                quantity: 1,
                //id: "",
                //google_business_vertical: "retail"
              }
            ],
            transaction_id: "",
            affiliation: "",
            value: this.bookingData.live_price, // Sum of (price * quantity) for all items.
            tax: 0,
            shipping: 0,
            coupon: "",
          }
        });
        
        
      }
      
      if(window.LogRocket) window.LogRocket.track('initiateCheckout')
      if(window.clarity) window.clarity("set", "checkout", "initiate")

      if(window.fbq) window.fbq('track', 'AddToCart', {
        content_name: this.bookingData.hotelName,
        content_category: process.env.VUE_APP_SITE_TITLE,
        content_ids: [1],
        content_type: 'product',
        value: this.bookingData.live_price,
        currency: 'EUR'
      });

      if(window.fbq) window.fbq('track', 'InitiateCheckout', {
        content_name: this.bookingData.hotelName,
        content_category: process.env.VUE_APP_SITE_TITLE,
        content_ids: [1],
        content_type: 'product',
        value: this.bookingData.live_price,
        currency: 'EUR'
      });


    },
    methods: {

      sidebarOrderConfirmed: function(data){

        let qty = 1
        if(data && data.qty) qty = data.qty

        if(window.fbq) window.fbq('track', 'Purchase', {
          contents: [{id: 1, quantity: qty}],
          content_name: this.bookingData.hotelName,
          content_category: process.env.VUE_APP_SITE_TITLE,
          content_type: 'product',
          value: this.bookingData.live_price*qty,
          currency: 'EUR',
          content_ids: [1],
          num_items: qty
        });

      }, // sidebarOrderConfirmed

    }, // methods
  }
</script>

<style lang="scss">

    #checkout {

      @include media-breakpoint-up(lg) {
          padding-top: 250px;
      }

      .credit-card
      {
          display:flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom:2rem;
          .icons
          {
              display:flex;
              flex:1;
              justify-content: center;
          }
          img,svg
          {
              width: auto;
              height: 24px;
              margin-right: 8px;            
          }
      }
          
      #content
      {
        margin-top:0;
        @include media-breakpoint-up(xxl)
        {
           width: 70%;
           flex: 70%;
        }
      }
      @include media-breakpoint-up(lg)
      {
        #sidebar {
          padding-right:0;
          padding-left:$grid-gutter-width/2;
          width: 40%;
          flex: 40%;
        }
      }
      @include media-breakpoint-up(xxl)
      {
        #sidebar {
          padding-left:$grid-gutter-width;
        }
      }
      #main
      {
        margin-top:0 !important;
      }
      @include media-breakpoint-down(md)
      {
        #main
        {
          margin-left:0;
          margin-right:0;
        }
      }

      .checkin-checkout-box
      {
        display:flex;
        &>div
        {
          width:50%;
        }
      }
      
      .svg-pin
      {
        margin-right:4px;
        margin-bottom:2px;
        path
        {
          fill:$black;
        }
      }

      .terms-link
      {
        @include customize-color("cta");
        cursor:pointer;
      }

      @include media-breakpoint-up(lg)
      {
        #content
        {
          padding-left:0;
          padding-right:0;
        }
      }
    }
</style>